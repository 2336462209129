<template>
  <common-page-edit
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    :routeNameShow="routeNameShow"
    :keyRoute="'FinancialConditionId'"
    :routeNameCreate="routeNameCreate"
    :backUrl="pathBack"
    @set-loadData="setLoadData"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    :paramsSend="paramsSend"
  >
    <template v-slot:block2>
      <FinancialData/>
    </template>
  </common-page-edit>
</template>
<script>
import { API_METHODS, FIELD_LANG_FINANCIAL_CONDITION } from "../../../../constant";
import { NAME_OBJECT } from "../../../../RoleConstanans";

import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import FinancialData from '../commercialTerms/FinancialData.vue';
import CommonPageEdit from '../../commonElement/CommonPageEdit.vue';

export default {
  name: "EditPageCommercialTerms",
  components: {
    CommonPageEdit,
    FinancialData
  },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "CommercialTermsShow",
      routeNameCreate: "CommercialTermsCreate",

      pathBack: "/commercialTerms",
      apiNameGet: API_METHODS.GET_FINANCIAL_CONDITION,
      apiNameCreate: API_METHODS.CREATE_FINANCIAL_CONDITION,
      apiNameUpdate: API_METHODS.UPDATE_FINANCIAL_CONDITION,
      loadData: null
    };
  },
  beforeRouteEnter(to, from, next) {
    let fromName = from ? from.name : null;
    console.log(fromName);
    next(vm => {

      if (fromName == "ContractEdit") {
        vm.pathBack = from.fullPath;
      }
      if (fromName == "ContractShow") {
        vm.pathBack = from.fullPath;
      }
    });
  },
  computed: {
    title() {
      let text1 = this.$t("Просмотр коммерческого условия");
      let text2 = this.$t("Создание коммерческого условия");
      let text3 = this.$t("Редактирование коммерческого условия");

      let findShow = this.$route.path.indexOf("show");
      if (findShow != -1) {
        return text1;
      }
      let findCreate = this.$route.path.indexOf("create");
      if (findCreate != -1) {
        return text2;
      }
      let findEdit = this.$route.path.indexOf("edit");
      if (findEdit != -1) {
        return text3;
      }
      return text1;
    },
    nameRoleEntity() {
      return NAME_OBJECT.commercialTerms;
    },
    paramsSend() {
      return {
        id: this.$route.params.FinancialConditionId || "0",
      };
    }
  },

  methods: {
    convertLoadData(res) {
      let loadData = res.object;
      let fieldInfoList = res.fieldInfoList;
      let scheme = { configForm: [] };

      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        langFieldName: FIELD_LANG_FINANCIAL_CONDITION
      });

      return {
        loadData,
        fieldInfoList,
        scheme
      };
    },
    setLoadData(loadData) {
      this.loadData = loadData;
    },

    paramsSave() {
      return {
        id: this.$route.params.FinancialConditionId || "0",
      };
    }
  }
};
</script>


