<template>
  <card>
    <el-tabs :tab-position="tabPosition" v-model="nowTab">
      <el-tab-pane :label="$t('Правила коммерческих условий')" name="rule">
          <CommercialTermRule v-if="nowTab=='rule'"/>
      </el-tab-pane>
      <el-tab-pane :label="$t('Атрибуты')"  name="valAttr">
        <AttrValueTable v-if="nowTab=='valAttr'" routeField="FinancialConditionId" 
        :prefixEditIn="`/commercialTerms/${$route.params.FinancialConditionId}`"
        />
      </el-tab-pane>
    </el-tabs>
  </card>
</template>
<script>
  import  CommercialTermRule from "../../../modules/commercialTerms/rule/CommercialTermRule.vue"
  import AttrValueTable from "../attrValue/AttrValueTable"
  import {SET_TAB_FIN} from "./store/type"
  import {
    Tabs,
    TabPane,
  } from 'element-ui';
 
  export default {
    name: 'PersonData',
    components: {
      ElTabs: Tabs,
      ElTabPane: TabPane,
      CommercialTermRule,
      AttrValueTable
    },
    data() {
      return {
        tabPosition: "top"
      };
    },
    computed: {
       nowTab: {
      set(tab) {
        this.$store.commit(SET_TAB_FIN, tab);
      },
      get() {
        return this.$store.state.commercialTerms.tabnow;
      }
    }
    }
  }
</script>
