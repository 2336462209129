<template>
  <the-table-page :titleTable="$t('Правила коммерческих условий')"
                  :tableData="tableData"
                  :load="load"
                  fieldId="financialConditionRuleId"
                  :updateData="updateData"
                  :fieldInfoList="fieldInfoList"
                  :searchStart="searchStart"
                  :sortData="sortData"
                  :setSort="setSort"
                  :deleteEl="deleteEl"
                  :prefixEdit="`/commercialTerms/${$route.params.FinancialConditionId}/rule`"
                  :prefixShow="`/commercialTerms/${$route.params.FinancialConditionId}/rule`"
                  :linkCreate="`/commercialTerms/${$route.params.FinancialConditionId}/rule/create`"
                  configName = "financialConditionRule"
                  :roleName="nameRoleEntity"
  ></the-table-page>
</template>
<script>

  import TheTablePage from "../../../TheTablePage.vue";
  import { COMMERCIAL_TERMS_RULE, ENTITY_NAME} from "../store/type";

  import {
    SET_MAIN_PAGINATION,
    SET_MAIN_PAGINATION_PER_PAGE,
    SET_MAIN_PAGINATION_PAGE,
    SET_MAIN_SORT_ENTITY,
    SET_MAIN_SEARCH_ENTITY
  } from "../../../../../src/store/type"

  import {READ, DELETE} from '../../../../store/type'
  import {mapState} from 'vuex';
  import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
  import {DEFAULT_PER_PAGE, TYPE_ERRORS, FIELD_LANG_FINANCIAL_CONDITION_RULE, GET_MEMORY_PER_PAGE} from '../../../../constant'
  import notify from '../../../../helpers/notify/notify'
  import convertLangDataToTable from '../../../../helpers/converters/convertLangDataToTable'
  import {NAME_OBJECT} from "../../../../RoleConstanans"
  export default {
    name: "CommercialTermRule",
    components: {
      TheTablePage
    },
    data() {
      return {
        load: false
      }
    },
    computed: {
      ...mapState({
        fieldInfoList: state => state.commercialTerms.fieldInfoList ,
        lang: state => state.common.lang,
        sortData: state => state.pagination.sortData,
      }),
      nameRoleEntity(){
      return NAME_OBJECT.financialConditionRule
    },
      tableData() {
          let langNow = this.$store.state.common.lang
          let dataNow = this.$store.state.commercialTerms.data || []
          if (dataNow && dataNow.length > 0) {
            dataNow = convertLangDataToTable(dataNow, langNow, FIELD_LANG_FINANCIAL_CONDITION_RULE)
            dataNow = dataNow.map(item=>{
              return { ...item, isActive: item.isActive? this.$t('Да'):this.$t('Нет')   }
            })
            return dataNow
          }
      }
    },
    watch: {
      lang() {
        this.initData()
      }
    },
    methods: {
      getData() {
        this.load= true
        this.$store.dispatch(COMMERCIAL_TERMS_RULE + READ, {self:this, FinancialConditionId: this.$route.params.FinancialConditionId }).then(res=>{
          this.load= false
        })
        .catch(err=>{
          this.load= false
          notify(this, this.$t('Ошибка получения данных'), TYPE_ERRORS.DANGER)
        })
      },
      updateData() {
        this.getData()
      },
      searchStart(search){
        this.$store.commit(SET_MAIN_SEARCH_ENTITY, search)
        this.getData()
      },
      setSort(val){
        this.$store.commit(SET_MAIN_SORT_ENTITY, val)
        this.getData()
      },
      deleteEl(id){
        this.$store.dispatch(COMMERCIAL_TERMS_RULE + DELETE, {  FinancialConditionId: this.$route.params.FinancialConditionId,  id: id, self:this} ).then(res=>{
          this.getData();
             if(res.errors && res.errors.length  == 0){notify(this, this.$t("Успешно"),TYPE_ERRORS.SUCCESS); }
        })
      },
      initData() {
        this.$store.commit(ENTITY_NAME + READ ,{ data: [], fieldInfoList: []})
        this.$store.commit( SET_MAIN_SORT_ENTITY,{})
        this.$store.commit( SET_MAIN_SEARCH_ENTITY, '')
        this.$store.commit( SET_MAIN_PAGINATION_PER_PAGE, GET_MEMORY_PER_PAGE())
        this.$store.commit( SET_MAIN_PAGINATION_PAGE, 1)
        this.getData()
      }
    },
    created() {
      this.initData()
    }
  }
</script>
